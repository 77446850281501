import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from './logoFQ.png';

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const navRef = useRef(null);

  const handleNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavOpen(false);
      }
    };

    const handleScroll = () => {
      setNavOpen(false);
    };

    const handleResize = () => {
      setNavOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='fixed top-0 left-0 right-0 z-50 overflow-x-hidden'>
      <div className='flex justify-between items-center h-15 max-w-[1800px] mx-auto px-4 border-b-2 border-gray-500 text-white bg-black bg-opacity-80' ref={navRef}>
        <img src={logo} alt="Logo" width="125" height="250" />
        <h2 className='flex justify-end font-mono font-bold text-[#16dbe9] border px-2 py-2 rounded-lg text-2xl'
          style={{ textShadow: '6px 6px 18px rgba(0, 0, 0, 0.9)' }}>
          Fast Quality Installation Network and Data Solutions
        </h2>
        <ul className='hidden md:flex' style={{ textShadow: '6px 6px 18px rgba(0, 0, 0, 0.9)' }}>
          <li className='p-4 text-[#16dbe9] font-semibold font-roboto-serif sm:text-2xl border-b-2 border-transparent hover:border-white'>
            <a href="#hero">Home</a>
          </li>
          <li className='p-4 text-[#16dbe9] font-semibold sm:text-2xl border-b-2 border-transparent hover:border-white'>
            <a href="#about-us">About</a>
          </li>
          <li className='p-4 text-[#16dbe9] font-semibold sm:text-2xl border-b-2 border-transparent hover:border-white'>
            <a href="#services">Services</a>
          </li>
          <li className='p-4 text-[#16dbe9] font-semibold sm:text-2xl border-b-2 border-transparent hover:border-white'>
            <a href="#projects">Projects</a>
          </li>
          <li className='p-4 text-[#16dbe9] font-semibold sm:text-2xl border-b-2 border-transparent hover:border-white'>
            <a href="#Contacts">Contacts</a>
          </li>
        </ul>
        <div onClick={handleNav} className='block md:hidden' aria-label={navOpen ? "Close menu" : "Open menu"}>
          {navOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div className={navOpen ? 'fixed left-0 top-24 w-full bg-gray-900 ease-in-out duration-500' : 'hidden md:hidden'}>
          <h1 className='text-3xl font-bold text-[#00df9a] m-4'>Fast Quality</h1>
          <ul className='uppercase p-4'>
            <li className='p-4 font-bold border-b-2 border-gray-500'><a href="#hero" onClick={handleNav}>Home</a></li>
            <li className='p-4 font-bold border-b-2 border-gray-500'><a href="#about-us" onClick={handleNav}>About</a></li>
            <li className='p-4 font-bold border-b-2 border-gray-500'><a href="#services" onClick={handleNav}>Services</a></li>
            <li className='p-4 font-bold border-b-2 border-gray-500'><a href="#projects" onClick={handleNav}>Projects</a></li>
            <li className='p-4 font-bold border-b-2 border-gray-500'><a href="#contacts" onClick={handleNav}>Contacts</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
