import React from 'react';
import Laptop from '../assets/image/about BG.jpg';

const AboutUs = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='custom-image w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] sm:text-2xl font-bold '>About Us</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>FAST QUALITY HISTORY</h1>
          <div className='flex flex-col space-y-4 text-justify'>
            <p>
              Fast Quality is a company that was established in 2008 and is committed to providing exceptional services in structured cabling and fiber optic uplink. The company was founded by <b>Mr. Armando V. Largo</b>, who has a wealth of professional experience in the technical field. Along with his son <b>Edsel Largo</b>, he started this company to provide top-notch services to its clients.
            </p>
            <p>
              Fast Quality is staffed with experienced and competent people who have a strong work ethic and values. The company is dedicated to providing optimal results to its clients and is known for delivering the best services in structured cabling and fiber optic uplink. Whether you need a simple installation or a complex one, Fast Quality is the best choice for your needs. Its team of experts ensures that every project is completed on time and to the highest standard.
            </p>
            <p>
              FG (Fast Quality), formerly Topnet Electrical and Cable Installation, was founded in 2008 and registered at the Department of Trade and Industry on June 10, 2008. The Company is a brainchild of Mr. Armando V. Largo, who has years of professional experience in the technical field and decided to put up this company with his son Edsel Largo.
            </p>
            <p>
              The company is committed to delivering the best service in structured cabling and fiber optic uplink. Fast Quality is manned by experienced and competent people whose work ethics and values are fervent. The organization is dedicated to providing optimal results for its clients.
            </p>
          </div>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3'>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;