import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cards from './Components/Cards';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Projects from './Components/Projects';
import AboutUs from './Components/AboutUs';
import Contacts from './Components/Contacts';
import Navbar from './Components/Navbar';

function App() {
  return (  
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />}></Route>
      </Routes>
    </Router>
  );
};

function MainContent() { 
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.fqnetworksolution.com",
    "logo": "https://www.fqnetworksolution.com/logoFQ.png",
    "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.fqnetworksolution.com/search?q={search_term_string}",
    "query-input": "required name=search_term_string"
    }
  };

  return (
    <div>
      <Navbar /> {/* Navbar placed within the MainContent */}
      
      {/* JSON-LD Structured Data */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />
      
      <div id="hero">
        <Hero />
      </div>
      <div id="about-us">
        <AboutUs />
      </div>
      <div id="services">
        <Cards />
      </div>
      <div id="projects">
        <Projects />
      </div>
      <div id="Contacts">
        <Contacts />
      </div>
      <Footer />
    </div>
  );
}

export default App;
